import * as React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import Home from "../../Pages/Home/Home";
import Properties from "../../Pages/Properties/Properties";
import Property from "../../Pages/Property/Property";
import Staff from "../../Pages/Staff/Staff";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/properties" element={<Properties />} />
                <Route path="/staff" element={<Staff />} />
                <Route path="/property/:propertyId" element={<Property />} />
                <Route path="*" element={<Navigate to="/" replace />}></Route>
            </Routes>
        </Router>
    );
};

export default App;

import * as React from "react";
import Menu from "./../../Components/Menu/Menu";

const Staff = () => {
    return (
        <div className="Staff">
            <Menu />
            <div>Staff test</div>
        </div>
    );
};

export default Staff;

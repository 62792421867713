import * as React from "react";

import { Image } from "primereact/image";
import { Menubar } from "primereact/menubar";
import { useNavigate } from "react-router-dom";

import "./Menu.css";

const Menu = () => {
    let navigate = useNavigate();
    const items = [
        {
            label: "Főoldal",
            icon: "pi pi-fw pi-home",
            command: () => {
                navigate("/");
            },
        },
        {
            label: "Ingatlanok",
            icon: "pi pi-fw pi-building",
            command: () => {
                navigate("/properties");
            },
        },
        {
            label: "Munkatársak",
            icon: "pi pi-fw pi-user",
            command: () => {
                navigate("/staff");
            },
        },
    ];

    return (
        <div className="Menu">
            <Image imageClassName="logo" alt="logo" src={require("../../logo.png")}></Image>
            <Menubar className="menu" model={items} />
        </div>
    );
};

export default Menu;

import React, { useState, useEffect } from "react";

import { ScrollTop } from "primereact/scrolltop";
import PropertyCard from "../../Components/PropertyCard/PropertyCard";
import Menu from "./../../Components/Menu/Menu";
import { Property as PropertyModel } from "./../../models/Property";
import ApiService from "./../../services/Api";
import "./Properties.css";

const Properties = () => {
    const [properties, setProperties] = useState([] as PropertyModel[]);

    useEffect(() => {
        fetch(`http://localhost/api/property/getpublicinfo`)
            .then((response) => response.json())
            .then((actualData) => console.log(actualData));

        const api = async () => {
            try {
                const propertyData = await ApiService.getProperties();
                setProperties(propertyData);
            } catch (err) {
                console.log(err);
            }
        };

        api();
    }, []);

    return (
        <div className="Properties">
            <ScrollTop />
            <Menu />
            <div className="PageContainer">
                {properties.map((property) => {
                    return <PropertyCard property={property}></PropertyCard>;
                })}
            </div>
        </div>
    );
};

export default Properties;

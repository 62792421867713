import { Property } from "./../models/Property";

const config = {
    //api: "http://localhost/api/property/getpublicinfo",
    api: "https://stage.api.katavera.hu/property/getPublicInfo",
    options: {
        headers: { "content-type": "application/json" },
    },
};

const getProperties = (): Promise<Array<Property>> => {
    return fetch(`${config.api}`, { ...config.options })
        .then((response) => handleResponse(response))
        .then((response) => response)
        .catch((error) => {
            console.log(error);
        });
};

const handleResponse = (response: any) => {
    // You can handle 400 errors as well.
    if (response.status === 200) {
        return response.json();
    } else {
        console.log("asd");
        throw Error(response.json());
    }
};

const ApiService = {
    getProperties,
};

export default ApiService;

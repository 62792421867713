import * as React from "react";
import Menu from "../../Components/Menu/Menu";
import "./Home.css";

const Home = () => {
    return (
        <div className="Home">
            <Menu />
            <div className="PageContainer"></div>
        </div>
    );
};

export default Home;

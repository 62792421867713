import * as React from "react";
import { Card } from "primereact/card";
import { Image } from "primereact/image";
import "primeicons/primeicons.css";

import { Property } from "./../../models/Property";
import "./PropertyCard.css";

const PropertyCard = ({ property }: { property: Property }) => {
    return (
        <Card
            className="cardContainer"
            header={
                <div className="imageContainer">
                    <div className="imageText">
                        <b>{Number(property.price).toLocaleString()} Ft</b>
                    </div>
                    <Image src="https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"></Image>
                </div>
            }
            title={property.sellType + " - " + property.type}
            subTitle={
                <span>
                    <i className="pi pi-map-marker"></i>
                    {property.address}
                </span>
            }
        >
            {property.description}
        </Card>
    );
};

export default PropertyCard;

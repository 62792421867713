import * as React from "react";
import { useParams } from "react-router-dom";

const Property = () => {
    let { propertyId } = useParams();

    return <div className="Property"> Property test propertyId: {propertyId}</div>;
};

export default Property;
